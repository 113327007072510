export const ADD_PRODUCT_REVIEW = 'add_product_review';
export const LOGIN = 'login';
export const NEW_PASSWORD = 'new_password';
export const REGISTER = 'register';
export const AVAILABILITY_NOTIFICATION = 'availability_notification';
export const NEWSLETTER = 'newsletter';
export const CONTACT = 'contact';
export const FORGOT_PASSWORD = 'forgot_password';
export const AUTH_FOR_LOYALTY_CLUB = 'auth_for_loyalty_club';
export const JOIN_MODIVO_CLUB_STEP_1 = 'join_modivo_club_step_1';
export const JOIN_MODIVO_CLUB_STEP_2 = 'join_modivo_club_step_2';
