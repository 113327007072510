<template>
    <InputField
        :value="value"
        :invalid="invalid"
        v-bind="attributes"
        v-on="listeners"
        @input="onInput($event)"
    >
        <template v-if="isSuccess" #button>
            <Icon class="tick" :icon="Tick" />
        </template>
    </InputField>
</template>

<script>
import { Tick } from '@eobuwie-ui/icons/v1/other';

import { InputField } from '@eobuwie-ui/components/InputField/v1';
import { Icon } from '@eobuwie-ui/components/Icon/v1';

export default {
    name: 'InputFieldWithTick',

    components: {
        Icon,
        InputField,
    },

    inheritAttrs: false,

    props: {
        value: {
            type: [String, Number],
            required: true,
        },

        invalid: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isDirty: false,
        };
    },

    computed: {
        isFilled() {
            const { value } = this;
            const valueType = typeof value;

            return (
                (valueType === 'number' || valueType === 'string') &&
                !!value.toString().length
            );
        },

        isSuccess() {
            return this.isDirty && this.isFilled && !this.invalid;
        },

        attributes() {
            const { value, invalid, ...attrs } = this.$attrs;

            return attrs;
        },

        listeners() {
            const { input, ...listeners } = this.$listeners;

            return listeners;
        },
    },

    beforeCreate() {
        this.Tick = Tick;
    },

    methods: {
        onInput(value) {
            if (!this.isDirty) {
                this.isDirty = true;
            }

            this.$emit('input', value);
        },
    },
};
</script>

<style scoped lang="scss">
.tick {
    @apply text-primary;
}
</style>
