import { createNamespacedHelpers } from 'vuex';
import { HIDE_RECAPTCHA_CLASS_NAME } from '@configs/class-names';

const { mapActions: mapActionsLayout } = createNamespacedHelpers('layout');

export default {
    beforeCreate() {
        this.hideReCaptchaHTMLClass = [HIDE_RECAPTCHA_CLASS_NAME];
    },

    beforeDestroy() {
        this.addHTMLClasses(this.hideReCaptchaHTMLClass);
    },

    methods: {
        ...mapActionsLayout(['addHTMLClasses', 'removeHTMLClasses']),

        async initRecaptcha() {
            await this.$recaptcha.init();
            this.removeHTMLClasses(this.hideReCaptchaHTMLClass);
        },
    },
};
