export default {
    methods: {
        createAgreementsDataObject() {
            this.agreementsData = {};

            if (this.checkboxAgreements?.length) {
                this.checkboxAgreements.forEach(agreement => {
                    this.$set(this.agreementsData, [agreement.code], false);
                });
            }

            if (this.infoAgreements?.length) {
                this.infoAgreements.forEach(agreement => {
                    this.$set(this.agreementsData, [agreement.code], true);
                });
            }
        },
        setGdprAgreementsDecision(agreementsData, viewGdprAgreements = []) {
            return Object.keys(agreementsData).map(key => {
                const agreement = viewGdprAgreements.find(
                    agree => agree.code === key
                );

                return {
                    agreement_id: agreement.id,
                    is_accepted: agreementsData[key],
                };
            });
        },
        getAgreementsForInputType(inputType, viewGdprAgreements = []) {
            return viewGdprAgreements.filter(
                agreement => agreement.input_type === inputType
            );
        },
        shouldNewsletterSubscribe(agreementsData, viewGdprAgreements = []) {
            return Object.keys(agreementsData)
                .map(key => {
                    const agreement = viewGdprAgreements.find(
                        agree => agree.code === key
                    );

                    return {
                        is_accepted: agreementsData[key],
                        is_newsletter_subscribe:
                            agreement.is_newsletter_subscribe,
                    };
                })
                .some(
                    decision =>
                        decision.is_newsletter_subscribe === 1 &&
                        decision.is_accepted === true
                );
        },
    },
};
