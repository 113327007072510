<template>
    <div class="customer-social-login">
        <LoginGoogle
            #default="{ signIn, isLogging }"
            :referrer-token="referrerToken"
            @login-success="onLoginSuccess(USER_LOGIN_LABEL_GOOGLE, $event)"
        >
            <ButtonSocialMedia
                :variant="GOOGLE_VARIANT"
                :type="BUTTON_TYPE"
                :disabled="shouldDisableLoginButton"
                :loading="isLogging"
                @click.native="
                    onSocialLoginClick(signIn, USER_LOGIN_LABEL_GOOGLE)
                "
            />
        </LoginGoogle>
        <LoginFacebook
            #default="{ signIn, isLogging }"
            :referrer-token="referrerToken"
            @login-success="onLoginSuccess(USER_LOGIN_LABEL_FACEBOOK, $event)"
        >
            <ButtonSocialMedia
                :variant="FACEBOOK_VARIANT"
                :type="BUTTON_TYPE"
                :disabled="shouldDisableLoginButton"
                :loading="isLogging"
                @click.native="
                    onSocialLoginClick(signIn, USER_LOGIN_LABEL_FACEBOOK)
                "
            />
        </LoginFacebook>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import debounce from 'lodash.debounce';

import { CLICK_DEBOUNCE_TIME } from '@configs/analytics-events';
import {
    CUSTOMER_REGISTRATION_PAGE,
    TERMS,
    INPUT_TYPE_EMPTY,
    SOURCE_TYPE,
} from '@configs/gdpr';

import {
    USER_LOGIN_LABEL_FACEBOOK,
    USER_LOGIN_LABEL_GOOGLE,
} from '@analytics-types/Analytics';

import gdpr from '@mixins/gdpr';

import LoginFacebook from '@molecules/LoginFacebook/LoginFacebook';
import LoginGoogle from '@molecules/LoginGoogle/LoginGoogle';

import {
    ButtonSocialMedia,
    BUTTON_SOCIAL_MEDIA_TYPES,
    BUTTON_SOCIAL_MEDIA_VARIANTS,
} from '@eobuwie-ui/components/ButtonSocialMedia/v1';

const { mapState: mapCustomerState } = createNamespacedHelpers('customer');

const {
    mapState: mapGdprState,
    mapActions: mapGdprActions,
} = createNamespacedHelpers('gdpr');

export default {
    name: 'CustomerSocialLogin',

    components: {
        LoginFacebook,
        LoginGoogle,
        ButtonSocialMedia,
    },

    mixins: [gdpr],

    props: {
        isDisabled: {
            type: Boolean,
            default: false,
        },

        referrerToken: {
            type: String,
            default: '',
        },

        gdprViewId: {
            type: String,
            default: CUSTOMER_REGISTRATION_PAGE,
        },
    },

    computed: {
        ...mapCustomerState(['fetchingCustomerInProgress', 'customerData']),
        ...mapGdprState({
            registrationGdprAgreements(state) {
                return state[this.gdprViewId];
            },
        }),

        shouldDisableLoginButton() {
            return this.fetchingCustomerInProgress || this.isDisabled;
        },
    },

    beforeCreate() {
        const { GOOGLE, FACEBOOK } = BUTTON_SOCIAL_MEDIA_VARIANTS;

        this.USER_LOGIN_LABEL_FACEBOOK = USER_LOGIN_LABEL_FACEBOOK;
        this.USER_LOGIN_LABEL_GOOGLE = USER_LOGIN_LABEL_GOOGLE;
        this.BUTTON_TYPE = BUTTON_SOCIAL_MEDIA_TYPES.BUTTON;
        this.GOOGLE_VARIANT = GOOGLE;
        this.FACEBOOK_VARIANT = FACEBOOK;
    },

    methods: {
        ...mapGdprActions(['getGdprData', 'setGdprData']),

        async onLoginSuccess(loginMethod, isRegistration) {
            if (isRegistration) {
                await this.setGdprAfterRegisterBySocialMedia(
                    this.customerData.email
                );
            }

            this.$emit('login-success', { type: loginMethod, isRegistration });
        },

        onSocialLoginClick: debounce(
            function (signIn, loginMethod) {
                this.$emit('social-login-click', loginMethod);
                signIn();
            },
            CLICK_DEBOUNCE_TIME,
            { leading: true }
        ),

        async setGdprAfterRegisterBySocialMedia(customerEmail) {
            if (!this.registrationGdprAgreements.length) {
                await this.getGdprData({
                    viewId: this.gdprViewId,
                    forViewId: this.gdprViewId,
                });
            }

            this.infoAgreements = this.getAgreementsForInputType(
                INPUT_TYPE_EMPTY,
                this.registrationGdprAgreements
            );

            this.createAgreementsDataObject();

            const gdprAgreementsDecision = this.setGdprAgreementsDecision(
                { ...this.agreementsData, [TERMS]: true },
                this.registrationGdprAgreements
            );

            await this.setGdprData({
                customerEmail,
                decisions: gdprAgreementsDecision,
                layoutHandle: this.gdprViewId,
                source: SOURCE_TYPE,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.customer-social-login {
    @apply flex gap-ui-7 flex-wrap justify-center;
}
</style>
