export const createFormFieldId = (formId, fieldId) => {
    return `${formId}__${fieldId}`;
};

export const createRequiredLabel = (label = '') => {
    return `${label}*`;
};

export const createLabelBasedOnCustomerType = (isCompany, basicLabel) => {
    return isCompany ? basicLabel : createRequiredLabel(basicLabel);
};
