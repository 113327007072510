<template>
    <ValidationProvider
        #default="{ errors, reset, failedRules, valid, validate }"
        ref="provider"
        :vid="virtualId"
        :name="name"
        :rules="rules"
        :debounce="debounce"
        slim
        :immediate="immediate"
    >
        <slot
            :errors="errors"
            :reset="reset"
            :failed-rules="failedRules"
            :valid="valid"
            :validate="validate"
        />
    </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

const DEBOUNCE_DEFAULT = 500;

export default {
    name: 'FieldValidator',

    components: {
        ValidationProvider,
    },

    props: {
        name: {
            type: String,
            required: true,
        },

        rules: {
            type: [String, Object],
            required: true,
        },

        vid: {
            type: [String, Number],
            default: '',
        },

        immediate: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            debounce: DEBOUNCE_DEFAULT,
        };
    },

    computed: {
        virtualId() {
            return this.vid || this.name;
        },
    },

    mounted() {
        this.errorWatcher = this.$watch(
            () => {
                return this.$refs?.provider?.errors || [];
            },
            errors => {
                this.debounce = errors.length ? 0 : DEBOUNCE_DEFAULT;
            },
            { immediate: true }
        );
    },

    beforeDestroy() {
        this.errorWatcher?.();
    },
};
</script>
