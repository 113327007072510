<template>
    <div class="text-with-line-separator">
        <BaseParagraph class="text">
            <slot />
        </BaseParagraph>
    </div>
</template>

<script>
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';

export default {
    name: 'TextWithLineSeparator',

    components: {
        BaseParagraph,
    },
};
</script>

<style lang="scss" scoped>
.text-with-line-separator {
    @apply flex justify-center relative;

    &:before {
        @apply w-full absolute top-1/2 bg-gray5 h-1p left-0;
        content: '';
    }

    .text {
        @apply bg-light px-2 text-xs leading-s z-1 text-text;
    }
}
</style>
