<script>
import { createNamespacedHelpers } from 'vuex';

import { GOOGLE_PROVIDER } from '@configs/auth-social-providers';

import { CHECKOUT_CART_PAGE_NAME } from '@router/names';

const { mapActions: mapActionsCustomer } = createNamespacedHelpers('customer');

export default {
    name: 'LoginGoogle',

    props: {
        referrerToken: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            isLogging: false,
        };
    },

    methods: {
        ...mapActionsCustomer(['socialLogin']),

        async signIn() {
            this.isLogging = true;

            const accessToken = await this.$googleAuth.signIn();

            if (accessToken) {
                this.loginApi(accessToken);
            }

            this.isLogging = false;
        },

        async loginApi(token) {
            const { loginSuccessful, isRegistration } = await this.socialLogin({
                token,
                provider: GOOGLE_PROVIDER,
                referrerToken: this.referrerToken || undefined,
                isCartRoute: this.$route.name === CHECKOUT_CART_PAGE_NAME,
            });

            if (loginSuccessful) {
                this.$emit('login-success', isRegistration);
            }
        },
    },

    render() {
        return this.$scopedSlots.default({
            signIn: this.signIn,
            isLogging: this.isLogging,
        });
    },
};
</script>
